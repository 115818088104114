.burger-menu {
	width: 40px;
	height: 40px;
	position: fixed;
	top: 30px;
	left: 40px;
	display: flex;
	justify-content: space-around;
	flex-flow: column nowrap;
	z-index: 20;
	cursor: pointer;
}

.burger-line {
	position: absolute;
	width: 30px;
	height: 4px;
	border-radius: 4px;
	transition: all 0.3s ease;
}

.burger-line-top {
	transform: translate(0, -8px);
}

.burger-line-bottom {
	transform: translate(0, 8px);
}

.burger-menu.open .burger-line-top {
	transform: translateY(0px) rotate(45deg);
}

.burger-menu.open .burger-line-middle {
	opacity: 0;
}
.burger-menu.open .burger-line-bottom {
	z-index: 909;
	transform: translateY(0px) rotate(-45deg);
}

.menu-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	z-index: 10;
	pointer-events: none;
	transition: opacity 0.5s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.menu-screen.open {
	opacity: 1;
	pointer-events: all;
	transition: opacity 0.5s cubic-bezier(0.1, 0.4, 0.2, 1);
}

/* .mobile-name {
	position: fixed;
	z-index: 10;
	left: calc(var(--pad) * 2);
	top: calc(var(--pad) * 2.5);
} */
