@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'helvetica-neue';
	src: url('./assets/fonts/helvetica-neue/HelveticaNeue-Light.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

.max-width {
	@apply lg:mx-auto lg:px-48 xl:px-52 px-5;
}
.bg-gradient {
	@apply bg-gradient-to-r from-sky-500 via-purple-500 to-pink-600;
}
.text-gradient {
	@apply bg-clip-text bg-gradient text-transparent;
}
.section {
	@apply pt-28 xl:pt-28 2xl:pt-44;
}
.text-content {
	@apply dark:text-light-content text-dark-content;
}

:root {
	--bg-dark: #0d0d0d;
	--bg-light: #f2f2f2;
	--text-darker: #1a1a1a;
	--text-light: #f2f2f2;
	--pad: 30px;
	--padMobile: 19.5px;
	--underline-color: #1a1a1a;
	--timeline-line: #1a1a1a;
	--mask-light: #ededed;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
	display: none;
}
* {
	margin: 0;
	padding: 0;
}
body {
	font-family: 'helvetica-neue';
	padding: 25px;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	display: flex;
	min-width: 325px;
	z-index: 10;
	font-feature-settings: 'palt';
	-webkit-text-size-adjust: none;
	-moz-osx-font-smoothing: grayscale;
	overscroll-behavior: none;
	transition-property: background-color;
	transition-duration: 0.9s;
	transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
}

body.dark {
	background-color: var(--bg-dark);
	color: var(--text-light);
	transition-property: background-color;
	transition-duration: 0.9s;
	transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
	--underline-color: #f2f2f2;
	--timeline-line: #f2f2f2;
	border: 4px solid var(--bg-dark);
}

/* old root 
	
#root {
	height: 100%;
	width: 100%;
	border-style: solid;
	border-width: 1px;
	min-width: 325px;
	overflow-y: hidden;
	border-color: rgba(250, 242, 255, 0.5);
	background: rgb(3, 2, 2);
	z-index: 11;
} 
*/

#root {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	white-space: nowrap;
	min-width: 300px;
}

#background {
	/* left: var(--pad);
	right: var(--pad);
	top: var(--pad);
	bottom: var(--pad); */
	position: absolute;
	left: clamp(-450px, -45%, -450px);
	bottom: clamp(-450px, -45%, -450px);
	width: clamp(500px, 85vw, 1100px);
	height: clamp(500px, 85vw, 1100px);
	border-radius: 50%;
	/* border: 1px solid black; */
	z-index: -1;
	pointer-events: none;
	overflow: hidden;
	transition-property: background-color;
	transition-duration: 0.9s;
	transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
}

@media screen and (max-width: 1170px) {
	#background {
		left: -250px !important;
		bottom: -250px !important;
	}
}

#background canvas {
	background: none !important;
}

/* #background::content {
	height: 100%;
	width: 100%;
	z-index: 10;
	background: linear-gradient(45deg, rgba(0, 0, 0, 0.136) 0, rgba(0, 0, 0, 0.607) 100%);
} */

#frame {
	position: fixed;
	z-index: 10;
	left: var(--pad);
	right: var(--pad);
	top: var(--pad);
	bottom: var(--pad);
	mix-blend-mode: difference;
	pointer-events: none;
	min-width: 0;
}

.frame_line-left {
	position: absolute;
	left: 0px;
	top: 0;
	width: 1px;
	height: 100%;
	opacity: 0.6;
}

.frame_line-right {
	position: absolute;
	right: 0px;
	top: 0;
	width: 1px;
	height: 100%;
	opacity: 0.6;
}

.frame_line-top {
	position: absolute;
	left: 0;
	top: 0px;
	width: 100%;
	height: 1px;
	opacity: 0.6;
}

.frame_line-bottom {
	position: absolute;
	left: 0;
	bottom: 0px;
	width: 100%;
	height: 1px;
	opacity: 0.6;
}

.mask {
	position: fixed;
	z-index: 3;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
}

.mask_top {
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	height: calc(var(--pad));
	transition-property: background-color;
	transition-duration: 0.9s;
	transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
	opacity: 1;
	backdrop-filter: blur(30px);
	z-index: 4;
}

.mask_bottom {
	position: absolute;
	bottom: -0px;
	left: 0;
	width: 100%;
	height: var(--pad);
	transition-property: background-color;
	transition-duration: 0.9s;
	transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
	opacity: 1;
	z-index: 4;
}

.mask_left {
	position: absolute;
	bottom: 20px;
	left: 0;
	width: var(--pad);
	height: 100%;
	transition-property: background-color;
	transition-duration: 0.9s;
	transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
	opacity: 1;
}

.mask_right {
	position: absolute;
	bottom: 20px;
	right: 0;
	width: var(--pad);
	height: 100%;
	transition-property: background-color;
	transition-duration: 0.9s;
	transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
	opacity: 1;
}

@media screen and (max-width: 768px) {
	.mask_right,
	.mask_left {
		width: calc(var(--padMobile) - 1px);
		bottom: var(--padMobile);
	}

	.mask_top,
	.mask_bottom {
		height: var(--padMobile);
	}

	#frame {
		position: fixed;
		z-index: 10;
		left: var(--padMobile);
		right: var(--padMobile);
		top: var(--padMobile);
		bottom: var(--padMobile);
	}
}

/* .blur {
	backdrop-filter: blur(2px);
} */

#content {
	margin: 31px 31px 0px 31px;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	/* border: green 2px solid; */
	height: calc(100vh - 62px);
	width: calc(100vw - 62px);
}

@media screen and (max-width: 768px) {
	#content {
		margin: 21px 21px 0px 21px;
		height: calc(100vh - 42px);
		width: calc(100vw - 42px);

		/* margin-bottom: -6100px !important; */
	}
}

/* nav */
.hover-underline-animation {
	display: inline-block;
	position: relative;
}

.hover-underline-animation::after {
	content: '';
	position: absolute;
	width: 20%;
	transform: scaleX(0);
	height: 1px;
	bottom: 6px;
	left: 0;
	background-color: var(--underline-color);
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

.name {
	position: fixed;
	z-index: 10;
	left: calc(var(--pad) * 2);
	top: calc(var(--pad) * 2);
	width: 50%;
	backdrop-filter: difference;
}

.about-1440,
.about {
	margin-right: calc(var(--pad) * 2);
}

.header {
	position: fixed;
	z-index: 10;
	left: calc(var(--pad) * 1.3);
	top: calc(var(--pad));
	backdrop-filter: difference;
	margin-top: 50px;
}

/** LIGHT DARK TOGGLE START **/
.lightdark {
	position: fixed;
	bottom: 31px;
	left: 5px;
	z-index: 10;
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	display: flex;
	justify-content: flex-start;
	height: 100%;
	width: 29px;
}

.baseline {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 3px;
}

.custom-checkbox {
	display: none;
}

.custom-box {
	cursor: pointer;
	width: 14px;
	height: 14px;
	flex-shrink: 0;
}

.custom-box + h5 {
	font-size: 18px;
	font-weight: 400;
	margin: 0;
	line-height: 1;
}

@media screen and (max-width: 768px) {
	.lightdark {
		bottom: 20px;
		left: -5px;
	}

	.custom-box + h5 {
		font-size: 16px;
	}

	.custom-box {
		width: 12px;
		height: 12px;
	}

	.baseline {
	gap: 2px;
}

}
/** LIGHT DARK TOGGLE FINISH **/

.wrapped-text {
	overflow-wrap: break-word;
	white-space: normal;
	/* text-wrap: balance; */
}

.page-title {
	position: absolute;
	top: 31px;
	right: 4px;
	z-index: 9;
	writing-mode: vertical-rl;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 15px;
}

@media screen and (max-width: 768px) {
	.page-title {
		right: 2px;
	}
}

.tech img {
	width: 50px;
	height: 50px;
	border-radius: 10%;
}

@media screen and (max-width: 468px) {
	.tech img {
		width: 20px;
		height: 20px;
		border-radius: 10%;
	}
}

.workImg img {
	border-radius: 10%;
}

input,
select,
textarea {
	color: var(--text-dark);
}

textarea:focus,
input:focus {
	color: var(--text-dark);
}


.extraSpace {
	height: calc(100% + 2000px);
}

.not-scrollable > * {
	overflow: hidden;
}

/*** WORK COMPONENT STYLING ***/
.work-header-text {
	white-space: normal !important;
	overflow-wrap: break-word !important;
	text-wrap: wrap !important;
	word-wrap: break-word !important;
	word-break: break-word; /** This is no longer supported but is supported by mobiles weirdly, so have to keep it **/
}

/*** PROJECTS COMPONENT STYLING ***/
.projects {
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.projectCardGrid {
	overflow-x: hidden;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 1.5em;
	margin: 3rem 7rem 3rem 7rem;
	min-width: 32rem;
	max-width: 70rem;
	padding: 0rem 3rem;
}

.projectCard {
	grid-column: span 3;
	height: auto;
	border-radius: 1rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	gap: 1.5rem;
	padding: 10px;
	box-shadow: 0.7rem 0.7rem 0.1rem rgba(0, 0, 0, 0.11);
	opacity: 1;
	border: 2px solid var(--mask-light);
}

body.dark .projectCard {
	background-color: rgb(26 26 26);
	border: 2px solid rgb(23, 23, 23);
}

.projectCard .details {
	display: none;
}

.projectCard img {
	border-radius: 0.2rem;
}

.projectCard.active .details {
	display: block;
	line-height: 1.2rem;
}

.projectCard.active {
	grid-column: 1 / span 6;
	grid-row: 1;
	order: 0;
	height: auto;
}

.projectCard.active img {
	max-width: 70%;
	min-width: 200px;
}

.projectCard.is-inactive {
	grid-column: span 2;
	height: auto;
}

@media screen and (max-width: 768px) {
	.projectCardGrid {
		grid-gap: 1rem;
		min-width: 25rem;
		padding: 0rem 1rem;
		margin-top: 50px;
	}

	.projectCard {
		font-size: 1.1rem;
		gap: 1.2rem;
		padding: 7px;
	}

	.projectCard.is-inactive {
		grid-column: span 3;
	}
}

@media screen and (max-width: 392px) {
	.projectCardGrid {
		grid-gap: 1rem;
		min-width: 15rem;
		padding: 0rem 2.5px;
		margin-top: 60px;
	}

	.projectCard {
		grid-column: span 3;
		font-size: 1rem;
		gap: 1rem;
		padding: 5px;
	}

	.projectCard.is-inactive {
		grid-column: span 6;
	}
}

.projectCard.is-inactive img {
	max-width: 70%;
	min-width: 80px;
}

.is-inactive {
	/* max-width: 100px !important; */
}

/*** Vertical Timeline Styles ***/

.vertical-timeline.vertical-timeline--two-columns::before {
	margin-left: 0px !important;
}

.vertical-timeline::before {
	width: 1px !important;
	background: var(--timeline-line) !important;
	opacity: 0.4;
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
	left: 130% !important;
}

.vertical-timeline--two-columns .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
	left: auto !important;
	right: 100% !important;
	text-align: center !important;
}

@media only screen and (min-width: 1170px) {
	.vertical-timeline.vertical-timeline--two-columns {
		width: 100% !important;
	}
}

[data-skill] {
	transition: transform 0.2s ease-out;
}

[data-skill]:hover {
	z-index: 10;
}

/* Optional: Add a subtle glow effect on hover */
[data-skill]:hover img {
	filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3));
}

.dark [data-skill]:hover img {
	filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3));
}

.spinner {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 0 auto;
}

.double-bounce1,
.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #333;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	animation: sk-bounce 2.0s infinite ease-in-out;
}

.dark .double-bounce1,
.dark .double-bounce2 {
	background-color: #fff;
}

.double-bounce2 {
	animation-delay: -1.0s;
}

@keyframes sk-bounce {
	0%, 100% { 
		transform: scale(0.0);
	} 50% { 
		transform: scale(1.0);
	}
}
